import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const BlogRecentPost = ({ data }) => {
    return (
        <div className="sidebar-widget relative py-8 px-5 lg:p-[35px] border-2 border-[#F7F5F1]">
            <h4 className="sidebar-widget-title text-[18px] leading-[1.2] mb-6 capitalize text-black">
                RECENT POST
            </h4>
            <div className="sidebar-widget-blogs">
                {data.items &&
                    data.items.map((item) => (
                        <div
                            key={`blog-post-${item.title}`}
                            className="flex gap-5 mb-4 last:mb-0"
                        >
                            {item?.image && (
                                <div className="sidebar-widget-blog-thumb w-20 shrink-0">
                                    <Link to={`/${item?.slug}`}>
                                        <GatsbyImage
                                            image={getImage(item?.image?.src)}
                                            alt={item?.image?.alt}
                                        />
                                    </Link>
                                </div>
                            )}
                            <div className="sidebar-widget-blog-content">
                                <ul className="sidebar-widget-blog-meta mb-3 last:mb-0">
                                    {item?.author && (
                                        <li className="text-[14px] flex items-center gap-[5px]">
                                            <i className="fi fi-user     text-[24px] leading-none flex items-center"></i>
                                            By
                                            <Link
                                                to="/"
                                                className="hover:text-primary text-black"
                                            >
                                                {item?.author}
                                            </Link>
                                        </li>
                                    )}
                                </ul>
                                {item?.title && (
                                    <h3 className="sidebar-widget-blog-title text-base md:text-sm xl:text-base font-medium leading-[1.5] tracking-[0.4px]">
                                        <Link to={`/${item?.slug}`}>
                                            {item?.title}
                                        </Link>
                                    </h3>
                                )}
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
};

BlogRecentPost.propTypes = {
    data: PropTypes.shape({
        items: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            })
        ),
    }),
};

export default BlogRecentPost;
