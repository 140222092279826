import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql, Link } from "gatsby";
import { flatDeep, slugify, containsObject } from "@utils/functions";

const TagsWidget = (props) => {
    const tagsQuery = useStaticQuery(graphql`
        query TagsQuery {
            allArticle {
                edges {
                    node {
                        tags {
                            slug
                            title
                        }
                    }
                }
            }
        }
    `);

    const tags = tagsQuery.allArticle.edges;
    const allTgs = flatDeep(tags.map((cate) => cate.node.tags));
    let cats = [];
    allTgs.forEach((cat) => {
        const obj = {
            title: cat.title,
            slug: cat.slug,
            count: 1,
        };
        const objIndex = containsObject(obj, cats);
        if (objIndex !== -1) {
            const prevCount = cats[objIndex].count;
            cats[objIndex] = {
                title: cat.title,
                slug: cat.slug,
                count: prevCount + 1,
            };
        } else {
            cats.push(obj);
        }
    });
    return (
        <div className="sidebar-widget relative p-[35px] border-2 border-[#F7F5F1]">
            <h4 className="sidebar-widget-title text-[18px] leading-[1.2] mb-6 capitalize text-black">
                POPULAR TAGS
            </h4>
            <div className="sidebar-widget-tags ">
                {cats.map((cat) => (
                    <Link
                        key={cat.slug}
                        to={`/tags/${cat.slug}`}
                        className="text-[13px] m-[6px] inline-block leading-[20px] relative p-[10px_20px] hover:bg-primary hover:text-white transition-all bg-[#F3F3F9] capitalize text-[#515151]"
                    >
                        {cat.title}
                    </Link>
                ))}
            </div>
        </div>
    );
};

TagsWidget.propTypes = { tags: PropTypes.shape({}) };

export default TagsWidget;
