import React from "react";
import PropTypes from "prop-types";
import BlogCard from "../../../components/blog-card";
import BlogRecentPost from "../../../components/widgets/blog-recent-post";
import CategorieWidget from "../../../components/widgets/categories";
import TagsWidget from "../../../components/widgets/tags-widgets";

const BlogRightSidebarArea = ({ data }) => {
    return (
        <section className="section-space-ptb border-b">
            <div className="container-full">
                <div className="grid md:grid-cols-12 gap-7 lg:gap-x-10 mt-10">
                    <div className="md:col-span-7 lg:col-span-8">
                        {data.allPosts &&
                            data.allPosts.map((item) => (
                                <BlogCard
                                    key={`blog-post-${item.title}`}
                                    title={item.title}
                                    author={item.author}
                                    date={item.postedAt.date}
                                    dateSlug={item.postedAt.slug}
                                    slug={item.slug}
                                    image={item.image}
                                    categories={item.categories}
                                    excerpt={item.excerpt}
                                />
                            ))}
                    </div>
                    <div className="md:col-span-5 lg:col-span-4">
                        <div className="blog-sidebar space-y-8">
                            <BlogRecentPost data={data} />
                            <CategorieWidget />
                            <TagsWidget />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

BlogRightSidebarArea.propTypes = {
    data: PropTypes.shape({
        section_title: PropTypes.shape({
            heading: PropTypes.string,
        }),
        allPosts: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            })
        ),
        items: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            })
        ),
    }),
};

export default BlogRightSidebarArea;
