import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql, Link } from "gatsby";
import { flatDeep, slugify, containsObject } from "@utils/functions";

const CategorieWidget = (props) => {
    const categoriesQuery = useStaticQuery(graphql`
        query CategoriesQuery {
            allArticle {
                edges {
                    node {
                        categories {
                            slug
                            title
                        }
                    }
                }
            }
        }
    `);
    const categories = categoriesQuery.allArticle.edges;
    const allCategories = flatDeep(
        categories.map((cate) => cate.node.categories)
    );
    let cats = [];
    allCategories.forEach((cat) => {
        const obj = {
            title: cat.title,
            slug: cat.slug,
            count: 1,
        };
        const objIndex = containsObject(obj, cats);
        if (objIndex !== -1) {
            const prevCount = cats[objIndex].count;
            cats[objIndex] = {
                title: cat.title,
                slug: cat.slug,
                count: prevCount + 1,
            };
        } else {
            cats.push(obj);
        }
    });
    return (
        <div className="sidebar-widget relative p-[35px] border-2 border-[#F7F5F1]">
            <h4 className="sidebar-widget-title text-[18px] leading-[1.2] mb-6 capitalize text-black">
                CATEGORIES
            </h4>
            <div className="sidebar-widget-categories">
                <ul>
                    {cats.map((cat) => (
                        <li key={cat.slug} className="mb-5 last:mb-0">
                            <Link
                                to={`/category/${cat.slug}`}
                                className="transition-all hover:before:bg-primary before:absolute before:rounded-full 
                                before:border-2 before:border-primary before:-translate-y-1/2 before:top-1/2 before:left-0
                                 before:w-2 before:h-2 before:content-[' '] hover:text-primary leading-[1.333] relative pl-5 
                                 text-black"
                            >
                                {cat.title}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

CategorieWidget.propTypes = { categories: PropTypes.shape({}) };

export default CategorieWidget;
