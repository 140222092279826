import React from "react";
import PropTypes from "prop-types";
import SEO from "@components/seo";
import Layout from "@layout";
import PageBreadcrumb from "@components/pagebreadcrumb";
import Footer from "../../layouts/footer/layout-01";
import Header from "../../layouts/header/layout-dark";
import BlogRightSidebarArea from "../../container/blog-page/layout-02";
import { graphql } from "gatsby";
import { normalizedData } from "@utils/functions";

const BlogTagPage = ({ data, location, pageContext }) => {
    const globalContent = normalizedData(data?.allGeneral?.nodes || []);
    return (
        <Layout>
            <SEO title="Blog Tags" pathname="/" />
            <Header data={{ ...globalContent?.["menu"] }} />
            <PageBreadcrumb
                pageContext={pageContext}
                location={location}
                title="Blog Tags"
            />
            <BlogRightSidebarArea
                data={{
                    items: data?.latestPosts?.nodes,
                    allPosts: data?.allPosts?.nodes,
                }}
            />
            <Footer data={{ ...globalContent?.["footer"] }} />
        </Layout>
    );
};

BlogTagPage.propTypes = {
    location: PropTypes.object,
    pageContext: PropTypes.object,
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        latestPosts: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allPosts: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export const query = graphql`
    query tagPageQuery($slug: String) {
        allGeneral {
            nodes {
                section
                id
                menu {
                    ...Menu
                }
                footer {
                    ...Footer
                }
            }
        }
        allPosts: allArticle(
            filter: { tags: { elemMatch: { slug: { eq: $slug } } } }
            sort: { fields: postedAt___date, order: DESC }
        ) {
            nodes {
                ...Articles
                image {
                    alt
                    src {
                        childImageSharp {
                            gatsbyImageData(height: 436, quality: 100)
                        }
                    }
                }
            }
        }
        latestPosts: allArticle(
            limit: 3
            sort: { fields: postedAt___date, order: DESC }
        ) {
            nodes {
                ...Articles
                image {
                    alt
                    src {
                        childImageSharp {
                            gatsbyImageData(height: 84, quality: 100, width: 80)
                        }
                    }
                }
            }
        }
    }
`;

export default BlogTagPage;
